.SectionRetos {
  padding: 40px 0;
  position: relative;
  z-index: 0;

  article {
    max-width: 680px;
    margin: 0 auto;
  }

  .challenger-content {
    @media (max-width: 576px) {
      padding: 0 16px
    }
    @media (max-width: 360px) {
      padding: 0 0px
    }

    h2,
    h3,
    p {
      margin: 0;
      color: var(--Text-Text-Title, #07254A);
    }

    h2 {
      text-align: center;
      font-family: $DINProBlack;
      font-size: var(--Size-2XL, 32px);
      font-style: normal;
      font-weight: 900;
      line-height: 36px;
      margin-bottom: 24px;
    }

    h3 {
      text-align: center;
      font-family: $DINProBlack;
      font-size: var(--Size-Md, 16px);
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      margin-bottom: 24px;

    }

    p.desc-challenger {
      text-align: center;
      font-size: var(--Size-Md, 16px);
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .challenger-cards{
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;


  }
}