.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background: #0b3b6fed;
    mix-blend-mode: multiply;
  }

  &__center {
    width: 430px;
    height: 100%;
    margin: 0 auto;
    max-width: 90%;
    z-index: 1;

    &--window {
      position: relative;
      background: #F4F2FA;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      border-radius: 6px;
      box-sizing: border-box;
      padding: 16px;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);

      &__image {
        height: 80px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 80% !important;
      }

      &__title {
        text-align: center;
        margin: 24px 0px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $colorText;
      }

      &__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin: 18px 0;
      }

      &__actions {
        margin: 20px 0px;

        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }

        .mc-button-container {
          margin: 10px 0px;
        }
      }

      &__terms {
        text-align: center;
        margin-bottom: 16px;

        a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          text-decoration-line: underline;
          color: $colorText;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 22px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  &.modalRedemptions {
    .mc-custom-modal__center {
      padding-top: 24px;
      width: 534px;
    }

    .mc-custom-modal__center--window {
      padding: var(--Size-Xs, 12px) var(--Size-Md, 16px) var(--Size-XL, 24px) var(--Size-Md, 16px);
      border-radius: var(--Size-XL, 24px);
      background: var(--Background-Background-Lowest, #FFF);

      .modal-content {
        .modal-header {
          h4 {
            color: var(--Text-Accent, #005EB8);
            text-align: center;
            font-family: $DINProBold;
            font-size: var(--Size-XL, 24px);
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            margin: 0;
            margin-top: 52px;
            margin-bottom: 24px;
          }
        }

        .modal-body {
          .award-info {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .mc-custom-modal__center--window__image {
              width: 80px;
              height: 80px;
              margin: 0;
              background-size: 100% !important;
            }

            .content-award {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              .mc-custom-modal__center--window__desc {
                color: var(--Text-Text-Title, #07254A);
                font-family: $DINProBold;
                font-size: var(--Size-Md, 16px);
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                margin: 0;
              }

              .mc-custom-modal__center--window__title {
                color: var(--Text-Accent, #005EB8);
                font-family: $DINProBold;
                font-size: var(--Size-Md, 16px);
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                margin: 10px 0;

              }

              .mc-custom-modal__center--window__terms {
                margin: 0;

                a {
                  color: var(--Text-Paragraph, #6D6D6D);
                  text-align: center;
                  font-size: var(--Size-Xxs, 10px);
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 0.1px;
                  text-decoration: none;
                }
              }
            }

          }

          .mc-custom-modal__center--window__actions {
            margin: 32px 0;

            .mc-button-container {
              margin: 0 auto;
              max-width: 253px;
            }
          }

          .bonus-reminder {
            color: var(--Text-Accent, #005EB8);
            text-align: center;
            font-family: $DINProBold;
            font-size: var(--Size-Xs, 12px);
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            /* 133.333% */
            margin-bottom: 32px;
            padding: 8px 16px;
            border-radius: 8px;
            background: var(--Background-Background-Medium, #B9DCFE);
            box-shadow: 0px 209px 58px 0px rgba(51, 135, 209, 0.00), 0px 134px 53px 0px rgba(51, 135, 209, 0.02), 0px 75px 45px 0px rgba(51, 135, 209, 0.08), 0px 33px 33px 0px rgba(51, 135, 209, 0.13), 0px 8px 18px 0px rgba(51, 135, 209, 0.15);
          }
        }

        .modal-footer {
          .text-tyc {
            p {
              margin: 0;
              color: var(--Text-Text-Title, #07254A);
              text-align: center;
              font-size: var(--Size-Sm, 14px);
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 16px;
            }
          }

          .tycDropdown {
            cursor: pointer;

            h3 {
              color: var(--Text-Accent, #005EB8);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              position: relative;
              margin: 0;
              margin-bottom: 11px;

              .iconDrop {
                position: absolute;
                right: 0;
                top: 30;

                transition: transform .3s linear;
                -webkit-transition: transform .3s linear;
                -moz-transition: transform .3s linear;
                -ms-transition: transform .3s linear;
                -o-transition: transform .3s linear;

                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);

                &.rotateIcon {
                  transform: rotate(0deg);
                  -webkit-transform: rotate(0deg);
                  -moz-transform: rotate(0deg);
                  -ms-transform: rotate(0deg);
                  -o-transform: rotate(0deg);
                }
              }
            }

            .content-tyc {
              height: 0;
              padding: 0;
              overflow: hidden;
              overflow-y: scroll;
              color: var(--Text-Paragraph, #6D6D6D);
              font-size: var(--Size-Sm, 14px);
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-align: justify;
              box-shadow: 0px 8px 16px 0px rgba(51, 135, 209, 0.25) inset;
              h4{
                font-size: 16px;
                margin: 0;
                margin-bottom: 16px;
                text-align: center;
              }
              ul {
                padding: 0 16px;

                li {
                  color: var(--Text-Paragraph, #6D6D6D);
                  font-size: var(--Size-Sm, 14px);
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 10px;
                }
              }

              &.active {
                height: 140px;
                padding: 16px;

              }
            }
          }
        }
      }
    }
  }

  &.globalModal {
    .mc-custom-modal__center {
      width: 328px;
    }

    .mc-custom-modal__center--window {
      border-radius: 16px;
      border: 1px solid var(--Outline-Low, #E0EEFE);
      background: var(--Background-Background-Low, #F0F7FF);

      .mc-custom-modal__center--window__title {
        color: var(--Text-Accent, #005EB8);
        text-align: center;
        font-family: $DINProBold;
        font-size: var(--Size-XL, 24px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin: 0;
        padding-top: 56px;
        margin-bottom: 16px;
      }

      .mc-custom-modal__center--window__desc {
        color: var(--Text-Text-Title, #07254A);
        text-align: center;
        font-size: var(--Size-Md, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
        margin-bottom: 16px;
      }

      .mc-custom-modal__center--window__actions {
        .mc-button-container {
          max-width: 253px;
          margin: 0 auto;
        }
      }
    }
  }

  &.specialAward {
    .mc-custom-modal__center {
      padding-top: 60px;
      width: 534px;
    }

    .mc-custom-modal__center--window {
      padding: var(--Size-Xs, 12px) var(--Size-Md, 16px) var(--Size-XL, 24px) var(--Size-Md, 16px);
      border-radius: var(--Size-XL, 24px);
      background: var(--Background-Background-Lowest, #FFF);

      .modal-content {
        padding-top: 56px;

        .modal-header {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;

          .mc-custom-modal__center--window__image {
            flex-basis: 50%;
            height: auto;
            width: auto;
            text-align: right;
            margin: 0;

            img {
              width: 140px;
              height: 140px;

            }
          }

          .mc-custom-modal__center--window__title {
            flex: 1 0 50%;
            color: var(--Text-Accent, #005EB8);
            font-family: $DINProBlack;
            font-size: var(--Size-Lg, 20px);
            font-style: normal;
            font-weight: 900;
            line-height: 24px;
            margin: 0;
            text-align: left;

          }
        }

        .modal-body {
          padding: var(--margin-h1, 16px) var(--margin-h1, 16px) 0px var(--margin-h1, 16px);
          height: 270px;
          background: var(--Color, #FFF);
          box-shadow: 0px 8px 16px 0px rgba(51, 135, 209, 0.25) inset;
          overflow-y: scroll;
          margin: 0;

          &.h-auto {

            height: auto;
          }

          .mc-custom-modal__center--window__desc {
            text-align: left;

            h3,
            h4,
            h5 {
              color: var(--Text-Text-Title, #07254A);
              text-align: center;
              font-family: $DINProBlack;
              font-size: var(--Size-Sm, 14px);
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              margin: 0;
              margin-bottom: 16px;
            }

            ul {
              padding-left: 25px;
              margin: 0;
              margin-bottom: 24px;

              li {
                color: var(--Text-Paragraph, #6D6D6D);
                font-size: var(--Size-Sm, 14px);
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin: 0;
              }
            }

            h5 {
              text-align: left;
              margin-bottom: 8px;
            }

            p {
              color: var(--Text-Paragraph, #6D6D6D);              
              font-size: var(--Size-Sm, 14px);
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              
            }
            a{
              color: #005EB8;
              line-break: anywhere;
              &:hover{
              font-weight: bold;
              }
            }
            strong, b{
              font-family: $DINProBold;
            }
          

          }

        }

        .modal-footer {
          .mc-custom-modal__center--window__actions {
            margin-bottom: 10px;
            .mc-button-container {
              width: 253px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}