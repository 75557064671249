@font-face {
    font-family: 'DINProRegular';
    src: url('../../components/fonts/DINProRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DINProBlack';
    src: url('../../components/fonts/DINProBlack.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'DINProBold';
    src: url('../../components/fonts/DINProBold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'DINProLight';
    src: url('../../components/fonts/DINProLight.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'DINProMedium';
    src: url('../../components/fonts/DINProMedium.ttf') format('truetype');
    font-display: swap;
}
