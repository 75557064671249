.CardRetos {
  width: 328px;
  border-radius: var(--size-md, 16px);
  background: var(--Background-Background-Low, #F0F7FF);
  position: relative;
  @media (max-width: 390px) {
    width: 100%;
   
  }
  &.disabled{

    filter: grayscale(1);
    -webkit-filter: grayscale(1);
  }
  .card-content
  {
    .card-header {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 72px;
      border-radius: var(--size-md, 16px) var(--size-md, 16px) 0px var(--size-md, 16px);
      background: var(--Background-Background-Medium, #B9DCFE);

      .logo {
        position: absolute;
        top: 0;
        left: -15px;

        img {
          width: 103px;
        }
      }

      .header-text {
        margin-left: 90px;

        h3,
        p {
          margin: 0;
        }

        h3 {
          color: var(--Text-Text-Title, #07254A);
          font-family: $DINProBlack;
          font-size: 24px;
          font-style: normal;
          font-weight: 900;
          line-height: 28px;
          margin-bottom: 4px;
        }

        p {
          color: var(--Text-Accent, #005EB8);
          font-family: $DINProBlack;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
      }
    }

    .card-body {
      padding: 24px 10px;
      min-height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      p {
        margin: 0;
        margin-bottom: 8px;
        color: var(--Text-Text-Title, #07254A);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-height: 40px;

        strong {
          color: var(--Text-Text-Title, #07254A);
          font-family: $DINProBlack;
          font-size: 15px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
      }

      h4 {
        margin: 0;


        &.no-challenger {
          color: #E20518;
          font-family: $DINProBlack;
          font-size: 15px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
      }
    }

    .card-footer {
      display: flex;
      align-items: center;
      text-align: center;
      min-height: 116px;
      padding: 10px 29px;
      border-radius: 0px 0px var(--size-md, 16px) var(--size-md, 16px);
      background: var(--Background-Background-Highest, #0B3B6F);

      h5 {
        margin: 0;
        color: var(--Text-Inverted, #F0F7FF);
        font-family: $DINProBlack;
        font-size: 15px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      &.winBG{
        background: linear-gradient(180deg, #005EB8 -1.98%, #002A64 122.77%);
      }

      
    }
  }


}