.SectionRedemptions {
  padding: 16px 0;
  background: linear-gradient(90deg, #002A64 23.44%, #005EB8 107.19%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  min-height: 420px;
  position: relative;
  z-index: 0;


  @media (min-width: 1367px) {
    background-position: top center;
    padding-top: 100px;
    min-height: 500px;
  }

  @media (max-width: 992px) {
    background-position: top center;
    padding: 40px 0;
    background-image: none !important;
    background: linear-gradient(180deg, #002A64 50.54%, #005EB8 100%) !important;
  }


  .content-redemptions {
    h2 {
      color: var(--Text-Inverted, #F0F7FF);

      /* Titles/h3-Black */
      font-family: $DINProBlack;
      font-size: var(--Size-2XL, 32px);
      font-style: normal;
      font-weight: 900;
      line-height: 36px;
      /* 112.5% */
    }

    .desc-redemptions {
      color: var(--Text-Inverted, #F0F7FF);
      font-family: $DINProBold;
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      /* 116.667% */
    }
  }

  .info-section-redemptions {
    margin: 0 auto;

    .img-not-redeem {
      margin-top: 30px;
      width: 100%;
      max-width: 430px;
      animation: imgScale 1s linear;
      -webkit-animation: imgScale 1s linear;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      transition: all .3s;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
    }

    .btnNotRedeem {
      display: none;

      @media (max-width: 992px) {
        display: block;
        margin: 0 auto;

      }
    }
  }

  @keyframes imgScale {
    0% {
      transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -o-transform: rotate(5deg);
    }

    30% {
      transform: rotate(-10deg);
      -webkit-transform: rotate(-10deg);
      -moz-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
      -o-transform: rotate(-10deg);
}

    50% {
      transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -o-transform: rotate(5deg);
}

    60% {
      transform: rotate(-10deg);
      -webkit-transform: rotate(-10deg);
      -moz-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
      -o-transform: rotate(-10deg);
}

    70% {
      transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -o-transform: rotate(5deg);
    }

    100% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
    }
  }
}