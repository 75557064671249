.mc-app-container {
  position: relative;
  display: block;
  width: 1100px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  @media (max-width: 768px) {
   &.no-padding-slider{
    padding: 0;
    @media only screen and (min-width: 992px) {
      z-index: -1;
    }
  }
  
  &.tyc{
    @media only screen and (min-width: 992px) {
      z-index: -1;
    }

   }
  }
  &.no-padding-slider{
    padding: 0;
    @media only screen and (min-width: 992px) {
      z-index: -1;
    }
   }

   &.tyc{
    z-index: 0;

  }

  @media only screen and (min-width: 992px) {
    max-width: 90%;
    margin: 0 auto;
    z-index: 2;
  }
}