.SectionPremioMayor {
  padding: 40px 0;
  background: var(--Button-Contrast, #EAF3F9);
  position: relative;
  z-index: 0;

  .section-title {
    h3 {
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-family: $DINProBold;
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;
      margin-bottom: 24px;
    }
  }

  .content-info {
    @media (max-width: 992px) {
      text-align: center;
    }

    h2 {
      color: var(--Text-Text-Title, #07254A);
      font-family: $DINProBlack;
      font-size: var(--Size-2XL, 32px);
      font-style: normal;
      font-weight: 900;
      line-height: 36px;
      margin: 0;
    }

    p {
      color: var(--Text-Accent, #005EB8);
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      max-width: 422px;
      min-height: 100px;
      margin: 0;
      margin-bottom: 24px;
      margin-top: 20px;

      @media (max-width: 992px) {
        min-height: auto;

      }
    }

    .btn-special-award {
      @media (max-width: 992px) {
        .mc-button-container {
          button {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .img-spacial-award {
    overflow: visible;
    

    img {
      transition: all .3s linear;
      -webkit-transition: all .3s linear;
      -moz-transition: all .3s linear;
      -ms-transition: all .3s linear;
      -o-transition: all .3s linear;

      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
    }
    &.gif{
      padding: 25px;
      img{
        border-radius: 19.556px;
        box-shadow: 0px var(--size-md, 16px) var(--32, 32px) 0px rgba(6, 46, 90, 0.40);
      }
    }
  }
}