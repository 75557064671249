.GoBack {
  button {
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    color: var(--Text-Accent, #005EB8);
    text-align: right;
    font-size: var(--Size-Md, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    span.text-button{
      position: relative;
      margin-left: 5px;
      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;        
        background: var(--Outline-High, #005EB8);
        width: 100%;
        height: 2px;
      }

    }


  }
}