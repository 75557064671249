.SectionLogin {
  padding: 16px 0;
  background-color: linear-gradient(90deg, #002A64 23.44%, #005EB8 107.19%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  min-height: 520px;
  position: relative;
  z-index: 0;
  
  @media (min-width: 1300px) {    
    background-position: top center;
    padding: 150px 0;
  }
  
  @media (max-width: 992px) {
     background-position: top center;
     padding: 40px 0;
   }
   @media (max-width: 576px) {
    background-image: none !important;
    background: linear-gradient(180deg, #002A64 50.54%, #005EB8 100%) !important;
  }

  article {
    width: 100%;
    max-width: 470px;
  }

  .login-content-text {
    h1 {
      color: var(--color-FFFFFF, #FFF);
      font-family: $DINProBlack;
      font-size: var(--Size-4XL, 40px);
      font-style: normal;
      font-weight: 900;
      line-height: 44px;
      margin: 0;
      margin-bottom: 24px;
    }

    .login-text1,
    .login-text2 {
      color: var(--color-FFFFFF, #FFF);      
      font-style: normal;
      font-weight: bold; 
      margin: 0;
      margin-bottom: 24px;         
    }
    
    .login-text1 {
      font-size: var(--Size-Lg, 20px);
      font-style: normal;
      line-height: 24px;      
    }
    
    .login-text2 {
      font-size: var(--Size-Sm, 14px);
      font-style: normal;
      line-height: normal;   
      @media (max-width: 992px) {
        margin-bottom: 40px;         
       
      }   
    }
  }
}