.SectionBenefits {
  .art-title {
    h2 {
      color: var(--Text-Text-Title, #07254A);
      text-align: center;      
      
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 900;
      line-height: 28px;  
    }
  }

  .content-benefits {
    padding: var(--M, 16px) 16px;
    border-radius: 16px;
    background: var(--Background-Background-Medium, #B9DCFE);

    ul {
      padding-left: 20px;

      li {
        color: var(--Text-Text-Title, #07254A);
        font-size: var(--Size-Md, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}