.SectionProgress {
  padding: 16px 0;
  min-height: 420px;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  position: relative;
  z-index: 0;
  @media (max-width: 992px) {
    background-position: 78%;    
  }
  @media (max-width: 576px) {
    background-image: none !important;
    background: linear-gradient(180deg, #002A64 50.54%, #005EB8 100%) !important;
  }

  @media (min-width: 1300px) {
    background-position: top center;
    padding: 150px 0;
  }

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  article {
    width: 100%;
    max-width: 470px;
  }

  .content-progress {


    h2 {
      color: var(--color-FFFFFF, #FFF);
      font-family: $DINProBlack;
      font-size: var(--Size-2XL, 32px);
      font-style: normal;
      font-weight: 900;
      line-height: 36px;
      margin: 0;
      margin-bottom: 24px;
      @media (max-width: 992px) {
        font-size: 40px;        
       
      }
      @media (max-width: 350px) {
        font-size: 32px;        
       
      }
    }

    .desc-progress {
      color: var(--color-FFFFFF, #FFF);
      font-family: $DINProBold;
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;
      margin-bottom: 24px;
      @media (max-width: 992px) {
        margin-bottom: 40px;
       
      }
    }
  }

  .info-progress {
    .progress-component {
      padding: var(--size-md, 16px) 17px;
      max-width: 328px;
      border-radius: 16px;
      border: 1px solid var(--Outline-Low, #E0EEFE);
      background: var(--Button-Hover-light, rgba(18, 50, 94, 0.40));

      .info-meta {
        margin-bottom: 16px;

        p {
          color: var(--color-FFFFFF, #FFF);
          font-family: $DINProBlack;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          margin: 0;

          strong {
            margin-left: 10px;
          }

          span {
            color: var(--Button-Disable-button, #D6EDFC);
            font-family: $DINProBlack;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
          }
        }
      }

      .progress-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        p {
          flex: 1 0 10%;
          color: var(--color-FFFFFF, #FFF);
          font-family: $DINProBlack;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;

        }

        .bar-content-progress {
          flex: 1 0 75%;
        }
      }

      .date-update {
        p {
          color: var(--color-FFFFFF, #FFF);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          margin-bottom: 16px;
          span{
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
      .line-progress{
        height: 1px;
        background: var(--Outline-Low, #E0EEFE);
        margin-bottom: 16px;
      }
    }
  }
}