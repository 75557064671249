.BonusReminder {
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: 10;
  display: flex;
  max-height: 44px;
  padding: 16px 12px;
  border-radius: 16px;
  border: var(--Size-Xxs, 2px) solid var(--Outline-Medium, #36A1FA);
  background: var(--Gradients-BPD, linear-gradient(180deg, #0575E6 0%, #023B82 132.61%));
  animation: showToast 1s linear;
  -webkit-animation: showToast 1s linear;
  

  .content {
    display: flex;
    max-width: 435px;
    align-items: center;
    gap: 32px;

    p {
      flex: 1 0 50%;
      color: var(--Text-Inverted, #F0F7FF);
      font-family: $DINProBold;
      font-size: var(--Size-Lg, 20px);
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 120% */
      margin: 0;

      @media (max-width: 992px) {
        font-size: 16px;

      }
    }

    .bt-Bonus-Reminder {
      flex: 1 0 20%;

    }

    .close-btn {
      display: inline-block;
      text-align: center;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

    }
  }

  @keyframes showToast {
    0% {
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
}
  }

  &.close {
    opacity: 0;
    visibility: hidden;
  }

}