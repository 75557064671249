.PreheaderAccelerator{
    padding: 0;
    background: var(--Background-Background-Medium, #B9DCFE);
    padding: var(--Size-Md, 12px) 0px;
    
}

.txt, .txt-emoji{
    margin: 0;
    text-align: center;
}

.txt-emoji{
    line-height: 24px;
    font-size: 18px;
}

.txt{
    font-size: 16px;
    line-height: 22px;
}

.goal{
    color: #005EB8;
}