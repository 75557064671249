.LastSesion {
  text-align: left;
  .last-sesion{
    text-align: left;
    color: var(--color-FFFFFF, #FFF);
    font-family: $DINProBold;
    font-size: var(--Size-Sm, 14px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;
    &.black{
      color: var(--Text-Text-Title, #07254A) !important;
    }
  }
}