.preheader {
  background: var(--Background-Background-Medium, #b9dcfe);
  margin: 0;
  display: flex;
  padding: var(--Size-Md, 12px) var(--Size-Md, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--Size-Lg, 16px);
  flex: 1 0 0;

  .contenedor {
    margin: 0;
    padding: 0;
    color: var(--Text-Text-Title, #07254a);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .participa, .texto2 {
    font-weight: 700 !important;
  }

  .texto, .texto2{
    margin: 0;
  }
}
