.SectionFAQS {
	padding: 0px 0 40px 0;
	position: relative;
	z-index: 0;
	@media (max-width: 992px) {
		padding: 20px 0 40px 0;
	 
	}
	article{
		max-width: 752px;
		margin: 0 auto;
	}

	h4 {
		color: var(--Text-Text-Title, #07254A);
		text-align: center;
		font-family: $DINProBlack;
		font-size: var(--Size-XL, 24px);
		font-style: normal;
		font-weight: 900;
		line-height: 28px;
		margin: 0;
		margin: 24px;
	}

	.link-to-faqrs {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		a {
			color: var(--Text-Accent, #005EB8);
			text-align: right;
			font-size: var(--Size-Md, 16px);
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			text-decoration: underline;
		}
	}
}