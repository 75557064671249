.SectionTyc {  
  padding: 24px;
  max-width: 693px;
  margin: 40px auto;
  border-radius: var(--size-md, 16px);
  border: 1px solid var(--Background-Accent, #005EB8);
  background: var(--Background-Accent, #005EB8);
  position: relative;
  z-index: 0;

  .content-tyc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    @media (max-width: 992px) {
     flex-direction: column;
    }

    h4 {
      color: var(--Text-Inverted, #F0F7FF);
      text-align: center;
      font-family: $DINProBold;
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;   
    }

    .btn-section-tyc {
      width: 253px;
    }
  }
}