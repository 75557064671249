.SectionMechanic {
  padding: 40px 0;
  padding-bottom: 0;
  position: relative;
  z-index: 0;

  h2 {
    color: var(--Text-Text-Title, #07254A);
    text-align: center;
    font-family: $DINProBlack;
    font-size: var(--Size-2XL, 32px);
    font-style: normal;
    font-weight: 900;
    line-height: 36px;
    margin: 0;
    margin: 24px;
  }

  .slider-mechanic {
    margin: 0 auto;

    @media (max-width: 576px) {
      transform: translateX(16px);
      -webkit-transform: translateX(16px);
      -moz-transform: translateX(16px);
      -ms-transform: translateX(16px);
      -o-transform: translateX(16px);
    }

    h5 {
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-family: $DINProBold;
      font-size: var(--Size-Lg, 20px);
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 16px 0 8px 0;
    }

    .slick-dots li {
      background-color: #B9DCFE;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }

    .slick-slider .slick-dots li.slick-active {
      border-radius: var(--Size-Lg, 20px) !important;
      background: var(--Button-Default, #005EB8);
      -webkit-border-radius: var(--Size-Lg, 20px) !important;
      -moz-border-radius: var(--Size-Lg, 20px) !important;
      -ms-border-radius: var(--Size-Lg, 20px) !important;
      -o-border-radius: var(--Size-Lg, 20px) !important;
    }

    .slick-prev, .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
  }
  }
}