.mc-login {
  position: relative;
  height: auto;

  &__form {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    max-width: 328px;
    border-radius: 16px;
    border: 1px solid var(--Outline-Medium, #36A1FA);
    background: var(--Background-Background-Lowest, #FFF);
    @media (max-width: 576px) {
      margin: 0 auto;
    }

    &--fields {
      position: relative;
      margin-bottom: 24px;
    }

    &--overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 5;
      background-color: #fff;
      opacity: 0.5;
      cursor: no-drop;
      border-radius: 16px;
    }
  }

  .login-info-form {
    color: var(--Text-Text-Title, #07254A);
    text-align: center;
    font-family: $DINProBold;
    font-size: var(--Size-Md, 16px);
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
  }
}

.mc-input-container {

  &__box {
    position: relative;

    label {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      display: block;

    }

    input,
    textarea,
    select {
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      border: 0px;
      outline: 0px;
      height: 50px;
      position: relative;
      border-radius: 6px;
      font-size: 16px;
      padding: 0 12px;
      min-width: 100%;
      max-width: 100%;
      border: 1px solid #C2C2C2;
      color: #919191;
      margin: 8px 0;

      &[type="date"] {
        text-transform: uppercase;
      }
    }

    input[type="text"] {
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid var(--Outline-High, #005EB8);
      background: var(--Background-Background-Low, #F0F7FF);
      color: var(--Text-Placeholder, #707070);
      font-size: var(--Size-Md, 16px);
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 137.5% */
    }

    textarea {
      padding-top: 5px;
      padding-bottom: 5px;
      resize: none;
      height: 130px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: none;
      background-size: 0px;
    }

    input::placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder {
      opacity: 1 !important;
    }

    &.terms {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 0px !important;
      cursor: pointer;
      font-size: 16px !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: normal !important;
      line-height: 8px;

      a {
        text-decoration: none;
        margin-left: 4px;
        cursor: pointer !important;

        color: var(--Text-Paragraph, #6D6D6D);
        font-size: var(--Size-Xxs, 10px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.1px;
        position: relative;
        z-index: 10;
        &:hover{
        text-decoration: underline;
        }
      }

      input {
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 2;
        opacity: 0;
      }

      label {
        display: inline;
        color: var(--Text-Paragraph, #6D6D6D);
        font-size: var(--Size-Xxs, 10px);
        font-style: normal;
        font-weight: 400;        
        letter-spacing: 0.1px;
      }

      .mc-input-checkbox__checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid #919191;
      }

      .mc-input-error {
        margin-top: 14px;
        line-height: 16px !important;
      }
    }

    &.terms:hover input~.mc-input-checkbox__checkmark {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &.terms input:checked~.mc-input-checkbox__checkmark {
      background-color: $primaryColor;
      border: 2px solid $primaryColor !important;
    }

    .mc-input-checkbox__checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    &.terms input:checked~.mc-input-checkbox__checkmark:after {
      display: block;
    }

    &.terms .mc-input-checkbox__checkmark:after {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg) scale(0.8);
      -ms-transform: rotate(45deg) scale(0.8);
      transform: rotate(45deg) scale(0.8);
    }

    .mc-input-by-character {
      input {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
        min-width: 20px;
        margin: 0 1%;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.mc-input-separated-container {
  input {
    min-width: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 1%;
    color: #000;
  }
}

.mc-input-container__box--error {
  input {
    border: 1px solid red !important;
  }
}

.mc-input-error {
  color: red;
  background-color: rgb(255, 244, 244);
  border: 1px solid red;
  font-size: 12px;
  display: block;
  padding: 8px 14px;
  margin: 8px 0;
  border-radius: 4px;
}