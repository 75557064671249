.mc-button-container {
  display: block;
  width: 100%;
  text-align: center;

  button {
    display: block;
    width: 100%;
    border: 0px;
    height: 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    cursor: pointer;
    border-radius: 8px;

    .inline-loader {
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__primary {
    button {      
      background: var(--Button-Default, #005EB8);
      color: #fff;

      &:hover {
        color: #FFFFFF;
        background: var(--Button-Hover, #07254A);
      }
      &.mc-button-container__status--inactive {        
        cursor: no-drop;
        opacity: 0.7;
        border-radius: 8px;
        border: 1px solid var(--Outline-High, #005EB8);
        background: var(--Button-Contrast, #EAF3F9);
        color: var(--Text-Text-Title, #07254A);
        text-align: center;
        font-family: $DINProBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  &__auxiliar {
    button {      
      border-radius: 8px;
      background: var(--Button-Hover, #07254A);
      color: #fff;

      &:hover {        
        background: var(--Button-Hover, #07254aa6);
      }
      &.mc-button-container__status--inactive {
        background-color: $primaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
      &.fit-content{
       width: 253px !important;
      }
    }
  }

  &__secondary {
    button {        
      border: 1px solid var(--Outline-High, #005EB8);
      background: var(--Button-Contrast, #EAF3F9);
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-family: $DINProBold;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        color: #FFFFFF;
        background: var(--Button-Hover, #07254A);
      }
      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }
}