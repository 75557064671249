.mc-progressBar-container {
  // width: 245px;
  position: relative;
  height: 35px;
  margin: 15px 0;
  border-radius: 16px;
  border: 1px solid var(--Outline-Low, #E0EEFE);
  background: var(--Button-Hover-light, rgba(18, 50, 94, 0.40));

  .mc-progressBar-progress {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 0;
    margin: auto;
    z-index: 1;
    border-radius: 16.5px;
    background: var(--Button-Disable-button, #D6EDFC);
    transition: width 3s ease-out;
    -webkit-transition: width 3s ease-out;
    -moz-transition: width 3s ease-out;
    -ms-transition: width 3s ease-out;
    -o-transition: width 3s ease-out;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $secondaryColor;
      border-radius: 4px;
      right: -4px;
      top: -2px;
      z-index: 1;
    }
  }

  .mc-progressBar-percent {
    z-index: 2;
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    color: $colorText;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    top: 6px;

    &.white {
      color: #fff;
    }
  }

  .mc-progressBar-bubble {
    position: absolute;
    width: 38px;
    height: auto;
    bottom: 2px;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      position: relative;
      left: -19px;
    }
  }

  .mc-progressBar-title {
    position: absolute;
    left: 0px;
    top: -26px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.5;
  }

  .mc-progressBar-label {
    position: absolute;
    right: 0px;
    top: -25px;
    font-size: 13px;
  }

  &.has-title {
    margin-top: 25px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.col-2 {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  .iconSpan {
    width: 35px;
    height: 35px;
    position: absolute;
    left: 0%;
    margin-left: 15px;
    z-index: 10;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transition: left 3s ease-out;
    -webkit-transition: left 3s ease-out;
    -moz-transition: left 3s ease-out;
    -ms-transition: left 3s ease-out;
    -o-transition: left 3s ease-out;
}

}