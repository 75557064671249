.mc-counter-down {
  position: relative;
	background-color: rgb(233, 233, 233);
	border: 1px solid gray;
	box-sizing: border-box;
	padding: 20px;
	text-align: center;

  ul {
    padding: 0px;
    margin: 0px;

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 20px;

      span {
        display: block;
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
}