.mc-faqs-container {
  margin: 28px 0px;

  .mc-accordeon-container {
    position: relative;
    background-color: #FFFFFF;
    margin: 14px 0px;
    &:nth-child(odd){
      background: var(--Background-Background-Low, #F0F7FF);
    }

    &.active {

      .mc-accordeon-toggle-icon {
        transform: rotate(-180deg) !important;
        top: 26px !important;
        -webkit-transform: rotate(-180deg) !important;
        -moz-transform: rotate(-180deg) !important;
        -ms-transform: rotate(-180deg) !important;
        -o-transform: rotate(-180deg) !important;
}

      .mc-accordeon-toggle{
        color: var(--Text-Accent, #005EB8);
      }
    }

    .mc-accordeon-toggle {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 16px 14px;
      padding-right: 58px;

      box-sizing: border-box;
      color: var(--Text-Text-Title, #07254A);      
      font-size: var(--Size-Lg, 20px);
      font-style: normal;
      font-weight: 900;
      line-height: 24px;
      /* 120% */

      &:hover {
        opacity: 0.8;
      }
    }

    .mc-accordeon-toggle-icon {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 16px;
      top: 13px;
      -webkit-transition: all .2s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .2s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .2s cubic-bezier(.77, 0, .175, 1);
      transition: all .2s cubic-bezier(.77, 0, .175, 1);

      svg {
        width: 100%;
        height: auto;
      }
    }

    .mc-accordeon-content {
      padding-bottom: 22px;
      padding-top: 0px;
      padding-right: 45px;
      padding-left: 16px;
      text-align: justify;

      color: var(--Text-Text-Title, #07254A);
      font-family: DINPro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &.mc-faqs-container-type-1 {}

  &.mc-faqs-container-type-2 {}
}