@import url(
  'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&family=PT+Sans+Narrow:wght@400;700&display=swap'
);
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

html, body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;

  * {
    font-family: $DINProRegular;
  }
}

.full-height {
  height: 100%;
}

.mc-tag-card {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background-color: $primaryColor;
  color: #fff;
  font-size: 10px;
  padding: 2px 5px;
  padding-bottom: 3px;

  &.position-left {
    right: auto;
    left: 10px;
  }
}
.mc-icon{
  display: inline-block;
  vertical-align: middle;
}
.fit-content{
  width: fit-content !important;
}