.SectionAwards {
  padding: 40px  0;
  position: relative;
  z-index: 0;

  .title-section-awards {
    h2 {
      color: var(--Text-Accent, #005EB8);
      text-align: center;
      font-family: $DINProBlack;
      font-size: var(--Size-4XL, 40px);
      font-style: normal;
      font-weight: 900;
      line-height: 44px;
      margin: 0;
      margin-bottom: 16px;
    }

    p {
      color: var(--Text-Text-Title, #07254A);
      text-align: center;      
      font-family: $DINProBold;
      font-size: var(--Size-XL, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;
    }
  }

  .awards-all-items{
    padding: 40px 0;
  }
}