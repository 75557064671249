.mc-page {
  position: relative;
  padding: 0 0 0px;

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 25px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }

  &.terms {
    padding: 32px 0;

    .titles-tyc {
      text-align: center;

      h1 {
        color: var(--Text-Accent, #005EB8);
        text-align: center;
        font-family: $DINProBlack;
        font-size: var(--Size-2XL, 32px);
        font-style: normal;
        font-weight: 900;
        line-height: 36px;
        margin: 24px 0;

      }
    }

    .content-tyc {
      margin: 0 auto;
      max-width: 760px;
      text-align: justify;
      margin-bottom: 24px;

      h3 {
        color: var(--Text-Text-Title, #07254A);
        
        font-size: var(--Size-XL, 24px);
        font-style: normal;
        font-weight: 900;
        line-height: 28px;
        margin: 0;
        margin-bottom: 16px;
      }

      p {
        margin: 0;
        color: var(--Text-Text-Title, #07254A);
        font-size: var(--Size-Md, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 16px;
      }

      ul {
        margin: 0 !important;

        &.ul_item12 {
          p {
            display: flex;
            align-items: flex-start;
          }

          strong {
            margin-right: 10px;
          }
        }
      }

      a {
        color: #005EB8;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  &.faqs {
    padding: 32px 0;
    margin: 0 auto;

    .titles-faq {
      h1 {
        color: var(--Text-Accent, #005EB8);
        text-align: center;
        font-family: $DINProBlack;
        font-size: var(--Size-2XL, 32px);
        font-style: normal;
        font-weight: 900;
        line-height: 36px;
        margin: 24px 0;

      }
    }

    .content-faq {
      margin: 0 auto;
      max-width: 751px;
    }
  }

  .mc-static-content {
    margin: 30px 0;


    @media only screen and (min-width: 992px) {
      margin: 50px 0;
    }

  }

  &.not-found {
    padding: 92px 0;

    @media (max-width: 992px) {
      padding: 40px 0;

    }

    .customRow {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 992px) {
        flex-direction: column;
        gap: 24px;
      }

      .customCol1 {
        flex: 1 0 50%;

        @media (max-width: 992px) {
          order: 2;

        }

        .content-404 {
          padding-left: 109px;
          max-width: 440px;

          @media (max-width: 992px) {
            text-align: center;
            padding-left: 0;

          }

          h2 {
            color: var(--Text-Accent, #005EB8);
            font-family: $DINProBlack;
            font-size: var(--Size-XL, 24px);
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
            margin: 0;
          }

          p {
            margin-bottom: 12px;
            color: var(--Text-Text-Title, #07254A);
            font-size: var(--Size-Md, 16px);
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

          }

          .mc-button-container {
            width: 253px;

            @media (max-width: 992px) {
              margin: 0 auto;
            }
          }
        }

      }

      .customCol2 {
        text-align: center;
        flex: 1 0 50%;

        @media (max-width: 992px) {
          order: 1;

        }

        img {
          display: inline-block;
          max-width: 328px;
          margin: 0 auto;
        }
      }
    }
  }
}