.mc-cardbox-container {
  position: relative;
  box-sizing: border-box;
  // max-width: 344px;
  margin: 0 16px;
  border-radius: var(--size-md, 16px);
  background: var(--Background-Background-Medium, #B9DCFE);
  padding-bottom: 16px;

  &>img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  &>h2 {
    font-size: 20px;
    color: $primaryColor;
    text-align: center;
  }

  &>div {            
    padding: 0 37px;
    color: var(--Text-Text-Title, #07254A);
    text-align: center;        
    font-size: var(--Size-Md, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    min-height: 110px; 
  }
}